import { client } from "./client";
import { User } from "../types/User";
import { Password } from "@mui/icons-material";

export function apiLogin(user: string | null, pass: string | null) {
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    email: user,
    password: pass,
  };
  return client.post("/login", body, { headers: headers });
}

export function apiPasswordReset(user: string | null) {
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    email: user
  };
  return client.post("/password_reset", body, { headers: headers });
}

export function apiPasswordResetFinalize(password: string | null, token: string | null) {
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    password: password,
    token: token
  };
  return client.post("/password_reset_finalize", body, { headers: headers });
}

export function apiChangePassword(password: string | null, token: string | null) {
  const body = {
    password: password,
    token: token
  };

  return client.post("/change_password", body);
}

export function getLocalUserState(): User | null {
  const userState = window.localStorage.getItem("authState");
  return userState != null ? JSON.parse(userState) : null;
}

export function getLocalUserToken(): string {
  const userState = window.localStorage.getItem("authState");
  return userState != null ? JSON.parse(userState).token : null;
}

export function setLocalUserState(userState: User) {
  window.localStorage.setItem("authState", JSON.stringify(userState));
}

export function clearLocalUserState() {
  window.localStorage.removeItem("authState");
}






import React, { useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ListSubheader from '@mui/material/ListSubheader';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import { DragItem } from './DragItem';

import DataTableInsert from '../../document/DataTableInsert';
import DataTableForm from '../../document/DataTableForm';
import DataTableEditor from '../../document/DataTableEditor';
import ParameterForm from '../../document/ParameterForm';

import BarChartIcon from '@mui/icons-material/BarChart';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import TitleIcon from '@mui/icons-material/Title';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import DataArrayIcon from '@mui/icons-material/DataArray';

import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TableChartIcon from '@mui/icons-material/TableChart';
import ImageIcon from '@mui/icons-material/Image';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import TocIcon from '@mui/icons-material/Toc';

import { Chart, Dash, Workspace} from '../../../../types/Dash';
import useActions, { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentWorkspaceRole } from "../../../auth/authSlice";
import { Typography } from '@mui/material';
import dashEditorSlice from '../../dashEditorSlice';
import { ViewArray } from "@mui/icons-material";

const drawerWidth = 275;

const contentList = [
  {
    icon: (<BarChartIcon/>),
    text: 'Bar Chart',
    dragType: "right-drawer-library-content",
    element: {
      id: -1, 
      content: '', 
      /*content_format: ContentFormat.Markdown, 
      content_type: ContentType.LibraryContent, */
      heading_level: 2,
    },
    enabled: true
  },
  {
    icon: (<MultilineChartIcon/>),
    text: 'Line Chart',
    dragType: "right-drawer-item",
    element: {
      id: -1, 
      content: '## Subheading', 
      //content_format: ContentFormat.Markdown, 
      //content_type: ContentType.Heading, 
      heading_level: 2,
    },
    enabled: true
  }
]


// REF: https://mui.com/components/drawers/#clipped-under-the-app-bar
export function RightDrawer(props: {
  dash: Dash
}) {

  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const [newDataTable, setNewDataTable] = useState(false);
  const [newDataRow, setNewDataRow] = useState(-1);
  const [newParameter, setNewParameter] = useState(false);

  const [editDataTable, setEditDataTable] = useState(-1);

  const actions = useActions();

  const handleSetEdittingDataTable = (id:number) => {

    actions.setEditingDataTable(id);

    setEditDataTable(id);
  }

  const handleNewRow = (id:number) => {

    actions.setEditingDataTable(id);

    setNewDataRow(id);
  }

  const drawer = (
    <Box sx={{ overflow: 'auto' }}>

      { /* (currentWorkspaceRole == "ADMIN" || currentWorkspaceRole == "CREATOR") && <List>
        {contentList.map((item, index) => (
          <DragItem 
            id={index} 
            key={index} 
            enabled={item.enabled} 
            data={item.element} 
            element={item.element} 
            type={item.dragType} 
            text={item.text} 
            icon={item.icon}/> 
        ))}
        </List> */}
      <Divider />
      <Box>
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <strong>DATA TABLES</strong>
            </ListSubheader>
          }
          >
          <Box>
            <ListItemButton
              id={"create_dt"}
              sx={{
                m: "0px",
                bgcolor: null,
                boxShadow: 1,
                borderRadius: 2,
              }}
              onClick={() => {
                setNewDataTable(true);
              }}
              >
              <ListItemIcon><AddCircleIcon></AddCircleIcon></ListItemIcon>
              <ListItemText primary={ "CREATE DATA TABLE" } />
            </ListItemButton>
          </Box>
          {
            props.dash.data_tables?.map( _ => (
              <Box>
                  <ListItemButton
                    id={"" + _.id}
                    sx={{
                      m: "0px",
                      bgcolor: null,
                      boxShadow: 1,
                      borderRadius: 2,
                    }}
                    
                    >
                    <ListItemIcon><DataArrayIcon></DataArrayIcon></ListItemIcon>
                    <ListItemText primary={ _.name } />
                    <ListItemSecondaryAction>
                      <IconButton aria-label="view"
                        onClick={() => {
                          handleSetEdittingDataTable(_.id);
                        }}>
                        <ViewArray />
                      </IconButton>
                      <IconButton edge="end" aria-label="addrow"
                        onClick={() => {
                          handleNewRow(_.id);
                        }}>
                        <AddCircleIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                    {/* Only show drop down if there are items */}
                    { /*subItems !== null ? open ? <ExpandLess /> : <ExpandMore /> : null */}
                  </ListItemButton>
              </Box>
            ))
          }
        </List>
      </Box>
      <Box>
        <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <strong>PARAMETERS</strong>
              </ListSubheader>
            }
            >
          <Box>
            <ListItemButton
              id={"create_parm"}
              sx={{
                m: "0px",
                bgcolor: null,
                boxShadow: 1,
                borderRadius: 2,
              }}
              onClick={() => {
                setNewParameter(true);
              }}
              >
              <ListItemIcon><AddCircleIcon></AddCircleIcon></ListItemIcon>
              <ListItemText primary={ "CREATE PARAMETER" } />
            </ListItemButton>
          </Box>
          {
          props.dash.parameters?.map( _ => (
            <Box>
                <ListItemButton
                  id={"" + _.id}
                  sx={{
                    m: "0px",
                    bgcolor: null,
                    boxShadow: 1,
                    borderRadius: 2,
                  }}
                  >
                  { /* <ListItemIcon>{props.icon}</ListItemIcon> */}
                  <ListItemText primary={ _.name + " = " + _.default_value} />
                  {/* Only show drop down if there are items */}
                  { /*subItems !== null ? open ? <ExpandLess /> : <ExpandMore /> : null */}
                </ListItemButton>
            </Box>
          ))
            }
        </List>
      </Box>
      <DataTableEditor
        open={editDataTable > 0}
        dash={props.dash}
        parentCloseHandle={() => {
          setEditDataTable(-1);
          //setAnchorEl(null);
        }}
      />
      <DataTableForm
        open={newDataTable}
        dash={props.dash}
        parentCloseHandle={() => {
          setNewDataTable(false);
          //setAnchorEl(null);
        }}
      />

      <DataTableInsert
        open={newDataRow > 0}
        dash={props.dash}
        dataTableId={newDataRow}
        parentCloseHandle={() => {
          setNewDataRow(-1);
          //setAnchorEl(null);
        }}
      />

      <ParameterForm
        open={newParameter}
        dash={props.dash}
        parentCloseHandle={() => {
          setNewParameter(false);
          //setAnchorEl(null);
        }}
      />
    </Box>
  );

  //console.log("AAAA", impact);

  const dispatch = useDispatch();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: { mx: 0, width: drawerWidth, boxSizing: 'border-box' },
      }}
      anchor="right"
    >
      <Toolbar />
        { (currentWorkspaceRole == "CREATOR" || currentWorkspaceRole == "ADMIN") && <Box>
          {drawer}
          
        </Box> }
    </Drawer>
  )
}

import { Action, ThunkAction, applyMiddleware, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import { rootSaga } from './sagas'; 
import { configureStore } from '@reduxjs/toolkit'

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
})
// export const store = createStore(
//   rootReducer,
//   applyMiddleware(sagaMiddleware)
//   // devTools: process.env.NODE_ENV !== 'production',
// );

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;

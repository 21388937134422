import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  attachChart,
  attachUser,
  backFolder,
  changeFolder,
  changePassword,
  changeUserRole,
  cloneDash,
  createChart,
  createChartAboveChart,
  createDash,
  createDataTable,
  createDataTableColumn,
  createDataTableRow,
  createFolder,
  createNavigation,
  createParameter,
  createRole,
  createUser,
  createUserInOrg,
  createUserWithOrg,
  createWorkspace,
  deleteChart,
  deleteDash,
  deleteFolder,
  deleteWorkspace,
  detachUser,
  executeChartCode,
  finalizeReset,
  //getDocumentImagePreview,
  getDashes,
  getFolders,
  getOrganizationUsers,
  getOrganizations,
  getRoles,
  getUsersInRole,
  getWorkspace,
  getWorkspaceSummary,
  getWorkspaceSummarySearch,
  getWorkspaces,
  linkDashToRole,
  linkFolderToRole,
  linkRole,
  login,  
  moveDash,
  reset,
  setEditingDash,
  setEditingDataTable,
  setOrganization,
  setPageNo,
  setWorkspace,
  switchCharts,
  //undeleteDocumentTemplate,
  unlinkRole,
  updateChart,
  updateChartCode,
  updateDash,
  
  
  //uploadDocumentElementImage,
} from "./actions";
import type { AppDispatch, RootState } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      login,
      reset,
      finalizeReset,
      changePassword,
      getOrganizations,
      setOrganization,
      getOrganizationUsers,
      getWorkspace,
      getWorkspaces,
      getWorkspaceSummary,
      getWorkspaceSummarySearch,
      setWorkspace,
      createWorkspace,
      deleteWorkspace,
      attachUser,
      createUser,
      createUserInOrg,
      createUserWithOrg,
      detachUser,
      changeUserRole,
      getDashes,
      getFolders,
      getRoles,
      getUsersInRole,
      createDash,
      updateDash,
      createFolder,
      changeFolder,
      backFolder,
      createRole,
      linkRole,
      unlinkRole,
      linkDashToRole,
      linkFolderToRole,
      setEditingDash,
      setEditingDataTable,
      //getDocumentImagePreview,
      deleteDash,
      deleteFolder,
      createChart,
      attachChart,
      deleteChart,
      updateChart,
      updateChartCode,
      executeChartCode,
      createChartAboveChart,
      switchCharts,
      cloneDash,
      moveDash,
      createDataTable,
      createDataTableColumn,
      createDataTableRow,
      createNavigation,
      createParameter,
      setPageNo,
    },
    dispatch
  );
};

export default useActions;

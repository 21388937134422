import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions from "../../app/hooks";
import { useSnackbar } from "notistack";
import {
  Organization,
  User,
  Workspace,
  WorkspaceUserRole,
} from "../../types/User";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { KeyboardReturnOutlined, Workspaces } from "@mui/icons-material";

/**
 * Edit the workspace if a workspace is passed in, otherwise create a new one.
 *
 * @export
 * @return {*}
 */
export default function ShowAdminUsers(props: {
  closeDialogHandler: any;
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [nameError, setNameError] = React.useState("");
  const [dialogError, setDialogError] = React.useState("");

  /*
  const [addUser, setAddUser] = React.useState<User | undefined>(
    props.organization.users && props.organization.users.length > 0
      ? props.organization.users[0]
      : undefined
  );
*/
  const [createUserMode, setCreateUserMode] = React.useState<boolean>(true);

  const [newUserEmail, setNewUserEmail] = React.useState<string>("");
  const [newOrganizationName, setNewOrganizationName] = React.useState<string>("");

  const handleEditUserEmail = (e: any) => {
    setNewUserEmail(e.target.value);
  };

  const handleEditOrganizationName = (e: any) => {
    setNewOrganizationName(e.target.value);
  };

  

  const handleDeleteUser = (user: User) => {
    //actions.detachUser({ workspace: props.workspace!, user: role.user });
  };

  const actions = useActions();

  const handleClose = () => {
    props.closeDialogHandler();
  };

  const handleCreateUser = (e: any) => {
    /*if (addUser == null) {
      setDialogError("Please select a user to add.");
      return;
    }*/
    //TODO: Check for duplicates.

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!newUserEmail.match(validRegex)) {
      enqueueSnackbar("Not a valid email address");
      return;
    }

    actions.createUserWithOrg({
      email: newUserEmail,
      organization_name: newOrganizationName
    });

    setCreateUserMode(false);

    props.closeDialogHandler();
  };

  const userListCard = (
    <Card>
      <Box>
        <CardHeader title="Create a new admin user and organization"></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8} sx={{marginBottom: "20px"}}>
              <InputLabel>Email</InputLabel>
              <Input
                value={newUserEmail}
                onChange={handleEditUserEmail}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={8} sx={{marginBottom: "20px"}}>
              <InputLabel>Organization Name</InputLabel>
              <Input
                value={newOrganizationName}
                onChange={handleEditOrganizationName}
                fullWidth={true}
              />
            </Grid>
            { /*<Grid item xs={5}>
              <InputLabel>Password</InputLabel>
              <Input
                value={newUserPassword}
                onChange={handleEditUserPassword}
              />
          </Grid> */}
          </Grid>
          
          <Box>
            <Button startIcon={<AddIcon />} onClick={(event) => handleCreateUser(event)}>
              Create User
            </Button>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );

  return (
    <Dialog
      open={true}
      onClose={(e) => {
        props.closeDialogHandler();
      }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        Admin Users (Super Admin Mode)
      </DialogTitle>
      <DialogContent>
        {dialogError !== "" ? (
          <Alert severity="error">{dialogError}</Alert>
        ) : null}
        {userListCard}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

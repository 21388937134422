import React, { useState } from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions from "../../../app/hooks";

import { Chart, Dash } from "../../../types/Dash";

function getXAxisName(dataString:string) {
  try {
    const fullPackage = JSON.parse(dataString);

    const data = fullPackage.data;

    if (data === undefined || data === null) {
      return "";
    }

    if (data[0] !== undefined && data[0] !== null) {
      if (data[0][0] !== undefined && data[0][0] !== null) {
        return data[0][0];
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }
  catch {
    return "";
  }
}


function getYAxisName(dataString:string) {
  try {
    const fullPackage = JSON.parse(dataString);

    const data = fullPackage.data;

    if (data === undefined || data === null) {
      return "";
    }

    if (data[0] !== undefined && data[0] !== null) {
      if (data[0][1] !== undefined && data[0][1] !== null) {
        return data[0][1];
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }
  catch {
    return "";
  }
}

function getDataPoints(chartType:string, dataString:string) {
  if (chartType !== "INPUT") {
    try {
      const fullPackage = JSON.parse(dataString);

      const data = fullPackage.data;

      if (data === undefined || data === null) {
        return "";
      }

      const values = [...data];
      values.shift();

      return JSON.stringify(values);
    }
    catch {
      return "";
    }
  }
  else {
    return dataString;
  }
}

export default function ChartEditForm(props: {
  chart: Chart;
  dash: Dash;
  open: boolean;
  parentCloseHandle: () => void;
}) {
  const actions = useActions();

  //console.log(props.chart);

  const [chartName, setChartName] = useState(props.chart.name);
  const [pageNo, setPageNo] = useState(props.chart.page_no);
  const [chartType, setChartType] = useState(props.chart.chart_type);
  const [codeLang, setCodeLang] = useState(props.chart.code_lang);

  const [xAxis, setXAxis] = useState(getXAxisName(props.chart.data_source));
  const [yAxis, setYAxis] = useState(getYAxisName(props.chart.data_source));

  const [dataPoints, setDataPoints] = useState(getDataPoints(props.chart.chart_type, props.chart.data_source));

  const [dataSource, setDataSource] = useState(props.chart.data_source);
  
  const [nameError, setNameError] = React.useState("");
  const [pageNoError, setPageNoError] = React.useState("");
  const [dataSourceError, setDataSourceError] = React.useState("");
  const [dataPointsError, setDataPointsError] = React.useState("");

  function updateChart() {

    try {

      const updatedChart = {...props.chart};

      
      updatedChart.name = chartName;
      updatedChart.chart_type = chartType;
      updatedChart.code_lang = codeLang;
      //updatedChart.data_source = dataSource;

      if (updatedChart.chart_type != "INPUT") {
        updatedChart.data_source = dataPoints == "" ? "" : (JSON.stringify({
          data: [[xAxis, yAxis], ...JSON.parse(dataPoints)]
        }));
      }
      else {
        updatedChart.data_source = dataPoints;
      }
      updatedChart.page_no = pageNo;

      setDataPointsError("");

      actions.updateChart(updatedChart);
      props.parentCloseHandle();
    }
    catch {
      setDataPointsError("Data points invalid");
    }
  }

  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setChartName(e.target.value);
  }

  function handlePageNoChange(e: any) {
    setPageNo(parseInt(e.target.value));
  }

  
  function handleDataSourceChange(e: any) {
    /*
    if (e.target.value.trim() === "") {
      setDataSourceError("Invalid purpose");
    } else setDataSourceError("");*/
    setDataSource(e.target.value);
  }

  function handleChartTypeChange(e: any) {
    setChartType(e.target.value);
  }

  function handleCodeLangChange(e: any) {
    setCodeLang(e.target.value);
  }

  function handleXAxisNameChange(e: any) {
    setXAxis(e.target.value);
  }

  function handleYAxisNameChange(e: any) {
    setYAxis(e.target.value);
  }

  function handleDataPointsChange(e: any) {
    setDataPoints(e.target.value);
  }

  return (
    <Dialog open={props.open} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>Edit Chart</DialogTitle>
      <DialogContent>
        { /* <DialogContentText>Edit the Chart</DialogContentText> */ }
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          error={nameError !== ""}
          fullWidth
          variant="standard"
          value={chartName}
          onChange={handleNameChange}
        />
        <TextField
          value={pageNo}
          onChange={handlePageNoChange}
          fullWidth={true}
          select
          label={"Page Number"}
          sx={{marginTop: "20px"}}
        >
          { props.dash.navigations?.length == 0 && <MenuItem value={1}>PAGE 1: PAGE ONE</MenuItem> }
          {
            props.dash.navigations?.map(__ => (
              <MenuItem key={"navmi_" + __.id} value={__.page_no}>{ __.page_no }: { __.name }</MenuItem>
            ))
          }
        </TextField>
        { chartType != "CODE" && chartType != "INPUT" && 
        <TextField
          margin="dense"
          id="xaxis"
          label="X Axis Name"
          fullWidth
          variant="standard"
          value={xAxis}
          onChange={handleXAxisNameChange}
        /> }
        { chartType != "CODE" && chartType != "INPUT" && 
        <TextField
          margin="dense"
          id="yaxis"
          label="Y Axis Name"
          fullWidth
          variant="standard"
          value={yAxis}
          onChange={handleYAxisNameChange}
        /> }

        { /* <TextField
          margin="dense"
          id="data source"
          label="Data Source"
          fullWidth
          error={dataSourceError !== ""}
          variant="standard"
          value={dataSource}
          onChange={handleDataSourceChange}
        /> */ }
        { chartType != "CODE" &&
        <TextField
          margin="dense"
          id="data points"
          label="Data Points"
          fullWidth
          error={dataPointsError !== ""}
          variant="standard"
          value={dataPoints}
          onChange={handleDataPointsChange}
          multiline
          rows={5}
        /> }
        <TextField
          value={chartType}
          onChange={handleChartTypeChange}
          fullWidth={true}
          select
          label={"Input Type"}
          sx={{marginTop: "20px"}}
        >
          <MenuItem key={"ct1"} value={"MULTILINE"}>Multiline</MenuItem>
          <MenuItem key={"ct2"} value={"PIE"}>Pie</MenuItem>
          <MenuItem key={"ct3"} value={"CODE"}>Code (Advanced)</MenuItem>
          <MenuItem key={"ct4"} value={"MUSIC"}>Music</MenuItem>
          <MenuItem key={"ct5"} value={"INPUT"}>Input</MenuItem>
        </TextField>
        { chartType == "CODE" &&
        <TextField
          value={codeLang}
          onChange={handleCodeLangChange}
          fullWidth={true}
          select
          label={"Code Type"}
          sx={{marginTop: "20px"}}
        >
          <MenuItem key={"cl1"} value={"PYTHON"}>Python</MenuItem>
          <MenuItem key={"cl2"} value={"PROMPT"}>Prompt</MenuItem>
          <MenuItem key={"cl3"} value={"RUST"}>Rust</MenuItem>
          <MenuItem key={"cl4"} value={"R"}>R</MenuItem>
          <MenuItem key={"cl5"} value={"COLA"}>CoLa</MenuItem>
          <MenuItem key={"cl6"} value={"COLAPREPYTHON"}>Python with CoLa Preprocessor</MenuItem>
          <MenuItem key={"cl7"} value={"COLAPRE"}>Rust with CoLa Preprocessor</MenuItem>
          <MenuItem key={"cl8"} value={"COLAPRER"}>R with CoLa Preprocessor</MenuItem>
        </TextField> }
        { chartType == "INPUT" &&
        <TextField
          value={codeLang}
          onChange={handleCodeLangChange}
          fullWidth={true}
          select
          label={"Input Type"}
          sx={{marginTop: "20px"}}
        >
          <MenuItem key={"cl1"} value={"DROPDOWN"}>Dropdown</MenuItem>
          <MenuItem key={"cl2"} value={"TEXTINPUT"}>Text Input</MenuItem>
          <MenuItem key={"cl3"} value={"NUMBERINPUT"}>Number Input</MenuItem>
        </TextField> }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={updateChart}
          type="submit"
          variant="contained"
          color="primary"
          
          disabled={nameError !== ""}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

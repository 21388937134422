import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import {
  clearLocalUserState,
  getLocalUserState,
  setLocalUserState,
} from "../../services/userAPI";
import {
  Organization,
  User,
  Workspace,
  WorkspaceUserRole,
} from "../../types/User";

export interface AuthState {
  user: User | null;
  organizations: Organization[] | null;
  workspaces: any[] | null;
  organization_owner: boolean;
  status: "logged-in" | "loading" | "logged-out" | "change-password";
  error: string | undefined;
}

const savedAuthState = getLocalUserState();

const initialState: AuthState = {
  user: savedAuthState != null ? savedAuthState : null,
  organizations: null,
  workspaces: null,
  organization_owner: false,
  status: "logged-out",
  error: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setStatus: (
      state,
      action: PayloadAction<{
        status: "logged-in" | "loading" | "logged-out" | "change-password";
        error: string | undefined;
      }>
    ) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    getUserFromLocalState: (state) => {
      state.user = getLocalUserState();
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
      
      if (state.user !== null && state.user.first_login) {
        
        if (state.user !== null) setLocalUserState(state.user);
        else clearLocalUserState();

        state.status = "change-password";
      }
      else {
        state.status = "logged-in";
        // Save the auth state to local storage.
        if (state.user != null) setLocalUserState(state.user);
        else clearLocalUserState();
      }
    },
    updateOrganizationUsers: (
      state,
      action: PayloadAction<{ organizationId: number; users: User[] | null }>
    ) => {
      const organization = state.organizations?.findIndex(
        (org) => org.id === action.payload.organizationId
      );
      if (organization != null) {
        state.organizations![organization] = Object.assign(
          state.organizations![organization],
          { users: action.payload.users }
        );
      }
    },
    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      state.organizations = action.payload;
    },
    setOrganizationOwner: (state, action: PayloadAction<{ is_owner: boolean}>) => {
      state.organization_owner = action.payload.is_owner;
    },
    setWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      state.workspaces = action.payload;
    },
    setWorkspaceLocal: (state, action: PayloadAction<Workspace>) => {
      const index = state.workspaces?.findIndex(
        (w) => w.id === action.payload.id
      );
      if (index != null) state.workspaces![index] = action.payload;
    },
    logout: (state) => {
      console.log("logout");
      //state = Object.assign({}, initialState);
      // Clear local store state.
      //clearLocalUserState();

      state.status = "logged-out";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {},
});

export const {
  setStatus,
  setError,
  logout,
  getUserFromLocalState,
  setUser,
  setOrganizations,
  setOrganizationOwner,
  updateOrganizationUsers,
  setWorkspaces,
  setWorkspaceLocal,
} = authSlice.actions;

//Selectors
export const selectAuth = (state: RootState) => state.auth;
export const selectUser = (state: RootState) => state.auth.user;
export const selectOrganizations = (state: RootState) =>
  state.auth.organizations;
export const selectWorkspaces = (state: RootState) => state.auth.workspaces;
export const selectCurrentOrganization = (state: RootState) =>
  state.auth?.organizations?.find(
    (o) => state.auth.user?.default_organization_id === o.id
  );
export const selectOrganizationOwner = (state: RootState) =>
  state.auth?.organization_owner;
export const selectCurrentWorkspace = (state: RootState) =>
  state.auth?.workspaces?.find(
    (w) => state.auth.user?.default_workspace_id === w.id
  );
export const selectCurrentWorkspaceRole = (state: RootState) => {
  const currentWorkspace = selectCurrentWorkspace(state);
  const currentUser = selectUser(state);
  const userRole = currentWorkspace?.user_roles?.find(
    (userRole: WorkspaceUserRole) => userRole.user.id === currentUser?.user_id
  );

  //console.log("999", currentWorkspace, userRole?.role_type);
  return userRole?.role_type;
  }
  
export default authSlice.reducer;

import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Dash } from "../../types/Dash";
import Box from "@mui/material/Box";
import useActions from "../../app/hooks";

export default function DashInfoForm(props: {
  parentCloseHandle: () => void;
  dash: Dash;
}) {
  const actions = useActions();

  const [name, setName] = useState(props.dash.name);
  const [terms, setTerms] = useState(props.dash.terms);
  
  const [nameError, setNameError] = React.useState("");
  const [termsError, setTermsError] = React.useState("");

  function updateDash() {
    const newDash = Object.assign({}, props.dash, {
      name: name,
      terms: terms,
    });
    actions.updateDash(newDash);
    props.parentCloseHandle();
  }

  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(e.target.value);
  }

  function handleTermsChange(e: any) {
    if (e.target.value.trim() === "") {
      setTermsError("Invalid terms");
    } else setTermsError("");
    setTerms(e.target.value);
  }

  return (
    <Dialog open={true} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>
        Document Template: {props.dash.name}
      </DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ p: 2, m: 1 }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            value={name}
            fullWidth
            variant="standard"
            onChange={handleNameChange}
          />
          <TextField
            margin="dense"
            id="name"
            label="Purpose"
            value={terms}
            fullWidth
            variant="standard"
            onChange={handleTermsChange}
          />
          
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={updateDash}
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            nameError !== "" || termsError !== "" 
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

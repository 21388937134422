// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import { getLocalUserToken } from "../services/userAPI";

//export const host = "http://localhost:8000"

export const host = "https://online.vainglorydash.com"

export async function client(endpoint, { body, isBinary, ...customConfig } = {}) {
    const headers = { 
      'Content-Type': 'application/json' 
    }
  
    // Get the auth token if it exists
    const token = getLocalUserToken();
    if(token)
      headers["Authorization"] = token;

    const config = {
      method: body ? 'POST' : 'GET',
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    }
  
    if (body) {
      config.body = JSON.stringify(body)
    }
  
    let data
    try {
      const response = await window.fetch(host + endpoint, config);
      if (response.ok) {
        data = await (isBinary ? response.blob() : response.json());
        // Return a result object similar to Axios
        return {
          status: response.status,
          data,
          headers: response.headers,
          url: response.url,
        }
      }
      //Error case
      const text = await response.text();
      // eslint-disable-next-line no-undef
      console.error(`Called: ${host + endpoint}\r\nStatus: ${response.statusText}\r\nResponse body: ${text}`)
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data)
    }
  }
  
  client.get = function (endpoint, customConfig = {}) {
    return client(endpoint, { ...customConfig, method: 'GET' })
  }
  
  client.post = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, body })
  }
  
  // TODO: Confirm these work
  client.put = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, body, method: 'PUT'})
  }

  client.delete = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, body, method: 'DELETE'})
  }

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions from "../../app/hooks";
import { useSnackbar } from "notistack";
import {
  Organization,
  User,
  Workspace,
  WorkspaceUserRole,
} from "../../types/User";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { KeyboardReturnOutlined, Workspaces } from "@mui/icons-material";

/**
 * Edit the workspace if a workspace is passed in, otherwise create a new one.
 *
 * @export
 * @return {*}
 */
export default function OrganizationConfigForm(props: {
  closeDialogHandler: any;
  organization: Organization;
  workspaces: Array<Workspace>;
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [nameError, setNameError] = React.useState("");
  const [dialogError, setDialogError] = React.useState("");

  const [addUser, setAddUser] = React.useState<User | undefined>(
    props.organization.users && props.organization.users.length > 0
      ? props.organization.users[0]
      : undefined
  );

  const [createUserMode, setCreateUserMode] = React.useState<boolean>(false);

  const [addWorkspaces, setAddWorkspaces] = React.useState<{id: number, role: string}[]>([]);

  const [newUserEmail, setNewUserEmail] = React.useState<string>("");
  const [newUserPassword, setNewUserPassword] = React.useState<string>("");
  const handleEditUserEmail = (e: any) => {
    setNewUserEmail(e.target.value);
  };

  const handleDeleteUser = (user: User) => {
    //actions.detachUser({ workspace: props.workspace!, user: role.user });
  };

  const actions = useActions();

  const handleClose = () => {
    props.closeDialogHandler();
  };

  const handleCreateUser = (e: any) => {
    /*if (addUser == null) {
      setDialogError("Please select a user to add.");
      return;
    }*/
    //TODO: Check for duplicates.

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!newUserEmail.match(validRegex)) {
      enqueueSnackbar("Not a valid email address");
      return;
    }

    actions.createUserInOrg({
      email: newUserEmail,
      password: newUserPassword,
      workspaces: addWorkspaces
    });

    setCreateUserMode(false);
  };

  const userEditCard = (
    <Card>
      { !createUserMode &&
      <Box>
        <CardContent>
          <Button onClick={() => setCreateUserMode(true)}>
            <AddIcon fontSize="large"></AddIcon>
            Invite a User
          </Button>
          {/* Existing role types */}
          {props.organization.users?.map((user) => (
            <Grid container spacing={2} key={"_user_data" + user.id}>
              <Grid item xs={5}>
                <Input
                  value={user.email}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5} textAlign="center">
                <IconButton disabled={true}
                  onClick={ (e) => handleDeleteUser(user)}>
                  <DeleteIcon fontSize="large"></DeleteIcon>
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </CardContent>
      </Box> }
      { createUserMode &&
      <Box>
        <CardHeader title="Invite a New User"></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8} sx={{marginBottom: "20px"}}>
              <InputLabel>Email</InputLabel>
              <Input
                value={newUserEmail}
                onChange={handleEditUserEmail}
                fullWidth={true}
              />
            </Grid>
            { /*<Grid item xs={5}>
              <InputLabel>Password</InputLabel>
              <Input
                value={newUserPassword}
                onChange={handleEditUserPassword}
              />
          </Grid> */}
          </Grid>
          
          {addWorkspaces.map((workspace, jj) => 
            <Grid container spacing={2} key={"ws_" + workspace.id + "_" + jj}>
              <Grid item xs={5}>
                <Select
                  label="Role"
                  value={workspace.id}
                  onChange={(event) => { 

                    if (addWorkspaces.map(_ => _.id).includes(Number(event.target.value))) {
                      return;
                    }

                    if (event.target.value !== null) {
                      setAddWorkspaces(addWorkspaces.map((_,kk) => {
                        if (jj == kk) {
                          return {
                            id: Number(event.target.value),
                            role: _.role,
                          };
                        }
                        else {
                          return _;
                        }
                      
                      }))
                    }
                  }}
                  fullWidth={true}
                >
                  { props.workspaces.map((ws) => <MenuItem value={ws.id}>
                    { ws.name }
                  </MenuItem> )}
              </Select>
              </Grid>
              <Grid item xs={5}>
                <Select
                  label="Role"
                  value={workspace.role}
                  onChange={(event) => {
                    setAddWorkspaces(addWorkspaces.map(_ => {
                      if (_.id == workspace.id) {
                        return {
                          id: _.id,
                          role: event.target.value
                        };
                      }
                      else {
                        return _;
                      }
                    
                    }))
                  }}
                  fullWidth={true}
                >
                  <MenuItem value="READER">Reader</MenuItem>
                  <MenuItem value="CREATOR">Creator</MenuItem>
                  <MenuItem value="PUBLISHER_CREATOR">Publisher</MenuItem>
                </Select>
              </Grid>
              
          </Grid>)}
          <Button sx={{marginBottom: "20px"}} onClick={ () => {
            if (props.workspaces.length > 0) {
              const missing = props.workspaces.filter(_ => !(addWorkspaces.map(__ => __.id).includes(_.id)));

              if (missing.length > 0) {
                setAddWorkspaces([...addWorkspaces, {id: missing[0].id, role: "READER"}]);
              }
            }
          }}>
            Add to Workspace
          </Button>
          <Box>
            <Button startIcon={<AddIcon />} onClick={(event) => handleCreateUser(event)}>
              Create User
            </Button>
          </Box>
        </CardContent>
      </Box> }
    </Card>
  );

  return (
    <Dialog
      open={true}
      onClose={(e) => {
        props.closeDialogHandler();
      }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        Organization Users
      </DialogTitle>
      <DialogContent>
        {dialogError !== "" ? (
          <Alert severity="error">{dialogError}</Alert>
        ) : null}
        {userEditCard}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

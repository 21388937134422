import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

export interface ViewPreferences {
  showCharts: boolean;
  showIDs: boolean;
  logDebug: boolean;
  showDebug: boolean;
}

const initialState: ViewPreferences = {
  showCharts: true,
  showIDs: false,
  logDebug: false,
  showDebug: false
};

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPreferencesViewSections: (state, action: PayloadAction<boolean>) => {
      state.showCharts = action.payload;
    },
    setPreferencesViewIDs: (state, action: PayloadAction<boolean>) => {
      state.showIDs = action.payload;
    },
    setPreferencesLogDebug: (state, action: PayloadAction<boolean>) => {
      state.logDebug = action.payload;
    },
    setPreferencesViewDebug: (state, action: PayloadAction<boolean>) => {
      state.showDebug = action.payload;
    },
  },
});

export const {
  setPreferencesViewSections,
  setPreferencesViewIDs,
  setPreferencesLogDebug,
  setPreferencesViewDebug
} = preferencesSlice.actions;

//Selectors
export const selectPreferences = (state: RootState) =>
  state.preferences;

export default preferencesSlice.reducer;

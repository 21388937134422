import { useEffect } from "react";
import { HeaderMenu } from "./features/headermenu/DashHeaderMenu";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Library from "./features/library/Library";

import useActions, { useAppSelector } from "./app/hooks";
import { selectEditingDash } from "./features/editor/dashEditorSlice";
import { selectAuth, selectUser } from "./features/auth/authSlice";

import DashEditorContainer from "./features/editor/DashEditorContainer";
import SignInModal from './features/auth/SignInModal';
import ResetModal from './features/auth/ResetModal';
import ChangePasswordModal from './features/auth/ChangePasswordModal';

import "./App.css";

function getParameterByName(name:string, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function App() {
  const actions = useActions();
  const authState = useAppSelector(selectAuth);

  const userData = useAppSelector(selectUser);
  //TODO: Implement a better state management
  const selectedDash = useAppSelector(selectEditingDash);

  //console.log("A", selectedDash);

  // For password reset
  if(window.location.pathname == "/reset") {
    const token = getParameterByName('token');

    if (token !== null) {
      return (<ResetModal authState={authState} token={token}></ResetModal>)
    }
    else {
      (window as any).location = "/";
    }
  }
 
  // External viewing
  /*
  if(window.location.pathname.split("/")[1] == "dash_outside") {
    const result = useEffect(() => {
      //const selectedDash = useAppSelector(selectEditingDash);

      actions.setEditingDash({
        id: parseInt(window.location.pathname.split("/")[2]),
        user_id: -1,
        created_at: "",
        updated_at: "",
        deleted_at: "",
        terms: "",
        name: "",
        parent_folder_id: -1,
        dashes_roles: [],
      });
    }, []);

    return (
      <Box sx={{bgColor: grey[200]}}>
        <CssBaseline />
        <DashEditorContainer from_outside={true}/>
      </Box>);
  }*/

  if(authState.status === "logged-out") {
    return (<SignInModal authState={authState} open={true}></SignInModal>)
  }
  
  if(authState.status === "change-password") {
    if (userData !== null) {
      return (<ChangePasswordModal email={ userData.email} authState={authState}></ChangePasswordModal>)
    }
  }

  const library =
    authState.status === "logged-in" ? (
      <Box >
        <Library></Library>
      </Box>
    ) : null;

  return (
    <Box sx={{bgColor: grey[200]}}>
      <CssBaseline />
      <HeaderMenu></HeaderMenu>
      {selectedDash !== null ? <DashEditorContainer  from_outside={false}/> : library}
    </Box>
  );
}

export default App;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CodeIcon from "@mui/icons-material/Code";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Chart } from "../../../../types/Dash";
import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/hooks";
import { setFocusChart } from "../../../library/librarySlice";
import { selectCharts, selectPageNo, setPageNo } from "../../dashEditorSlice";
import TableOfContentsItem from "./TableOfContentsChart";
import ListSubheader from "@mui/material/ListSubheader";
import { selectEditingDash } from "../../dashEditorSlice";

export function TableOfContents(props: any) {
  const actions = useActions();

  const charts = useAppSelector(selectCharts);

  const dispatch = useAppDispatch();

  const editingDash = useAppSelector(selectEditingDash);
  const currentPage = useAppSelector(selectPageNo);

  /**
   * Sort sections when they are dragged around.
   *
   * @param {*} result
   * @return {*}
   */
  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }
    actions.switchCharts({
      sourceChart: charts[result.source.index],
      destinationChart: charts[result.destination.index],
    });
  }

  return (
    <Box>
    
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <strong>STRUCTURE</strong>
          </ListSubheader>
        }
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="section_droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
              >
                { charts.map((chart: Chart, index: number) => (
                  <Draggable
                    key={chart.id}
                    draggableId={String(chart.id)}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // style={getItemStyle(
                        //   snapshot.isDragging,
                        //   provided.draggableProps.style
                        // )}
                        onClick={() => 
                          { 
                            if (currentPage != chart.page_no) {
                              dispatch(setPageNo(chart.page_no));
                              dispatch(setFocusChart(chart));
                            }
                            else {
                              dispatch(setFocusChart(chart));
                            }
                          }}
                      >
                        <TableOfContentsItem
                          id={index}
                          key={index}
                          chart={chart}
                          icon={<CodeIcon color="primary" />}
                          isDragging={snapshot.isDragging}
                          draggableProps={provided.dragHandleProps}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
        
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    </Box>
  );
}

// .filter(_ => _.pending_deleted_at !== null || _.pending_heading !== null ).

import { select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import * as actions from "../actions";
import {
  AuthState, 
  selectAuth,
  selectUser,
  setError as setAuthError,
  setStatus as setAuthStatus,
  setOrganizationOwner,
  setOrganizations,
  setUser,
  updateOrganizationUsers,
  
} from "../../features/auth/authSlice";
import { Organization, User } from "../../types/User";
import {
  apiCreateUserInOrg,
  apiCreateUserWithOrg,
  apiGetOrganizationUsers,
  apiGetOrganizations,
} from "../../services/organizationAPI";
import { clearDash } from "../../features/editor/dashEditorSlice";

/**
 * Get all organizations, confirm that the user has selected
 * a valid organization, and then get all workspaces.
 *
 * @param {ReturnType<typeof actions.getOrganizations>} action
 * @return {*}
 */
export function* getOrganizations(
  action: ReturnType<typeof actions.getOrganizations>
) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  try {
    const response = yield call(apiGetOrganizations);

    // Set the organizations.
    const organizations = response.data.organizations;
    yield put(setOrganizations(organizations));

    const userState = yield select(selectUser);
    const orgId = userState.default_organization_id;
    let defaultOrg = organizations.find((o: Organization) => o.id === orgId);

    if (organizations.length > 0) {
      if (!defaultOrg) {
        console.warn(
          `Cannot find user's default organization ${orgId}, resetting to first organization.`
        );
        // Select the first organization.
        defaultOrg = organizations[0];
        const updatedUser = Object.assign({}, userState, {
          default_organization_id: defaultOrg.id,
        });

        // Update the user
        yield put(setUser(updatedUser));

        
      }

      const link = (response.data.links as any[]).filter(_ => _.organization_id == defaultOrg.id);
      if (link.length > 0) {
        yield put(setOrganizationOwner({is_owner: link[0].org_role == "ADMIN"}));
      }

      // Get the users for this organization
      yield put(actions.getOrganizationUsers());

      // Get the workspaces for this organization
      yield put(actions.getWorkspaces());
    }

    yield put(setAuthStatus({ status: "logged-in", error: undefined }));
  } catch (e) {
    console.error(String(e));
    yield put(setAuthError(String(e)));
    return;
  }
}

/**
 * Set the current organization and refresh downstream slices.
 *
 * @param {ReturnType<typeof actions.setOrganization>} action
 * @return {*}
 */
export function* setOrganization(
  action: ReturnType<typeof actions.setOrganization>
) {
  try {
    const organization = action.payload;

    const userState = (yield select(selectUser)) as User;
    const updatedUser = Object.assign({}, userState, {
      default_organization_id: organization!.id,
      default_workspace_id: -1,
    });

    // Clear any currently edited document
    yield put(clearDash());

    // Update the user
    yield put(setUser(updatedUser));

    // Get the users for this organization
    yield put(actions.getOrganizationUsers());

    // Get the workspaces for this organization
    yield put(actions.getWorkspaces());
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

// export function* getOrgUsers(
//   action: ReturnType<typeof actions.getOrgUsers>
// ) {

// }

/**
 * Get all users in the current organization.
 *
 * @param {ReturnType<typeof actions.getOrganizationUsers>} action
 * @return {*}
 */
export function* getOrganizationUsers(
  action: ReturnType<typeof actions.getOrganizationUsers>
) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiGetOrganizationUsers,
      userState.default_organization_id
    );

    yield put(
      updateOrganizationUsers({
        organizationId: userState.default_organization_id,
        users: response.data?.users,
      })
    );

    //console.log("HERE!!!");

    yield put(setAuthStatus({ status: "logged-in", error: undefined }));
  } catch (e) {
    console.error(String(e));
    yield put(setAuthError(String(e)));
    return;
  }
}

export function* createUserInOrg(action: ReturnType<typeof actions.createUserInOrg>) {
  try {
    const email = action.payload.email,
      password = action.payload.password,
      workspaces = action.payload.workspaces;

    const authState = (yield select(selectAuth)) as AuthState;

    const response = yield apiCreateUserInOrg(
      authState.user!.default_organization_id,
      email,
      password,
      workspaces
    );

    // Get new user list
    yield put(actions.getOrganizationUsers());
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

export function* createUserWithOrg(action: ReturnType<typeof actions.createUserWithOrg>) {
  try {
    const email = action.payload.email,
      organization_name = action.payload.organization_name;

    const authState = (yield select(selectAuth)) as AuthState;

    const response = yield apiCreateUserWithOrg(
      email,
      organization_name
    );

    // Get new user list
    yield put(actions.getOrganizationUsers());
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}
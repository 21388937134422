import React from "react";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";

import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearPreview,
  selectFilteredDashes,
  selectFilteredFolders,
  selectFolderStack,
  selectLibrary,
  selectRoleId,
  selectRoles,
  selectRolesMode,
  selectUsersInRole,
  setError,
} from "./librarySlice";
import ImageList from "@mui/material/ImageList";
import Toolbar from "@mui/material/Toolbar";
import Alert from "@mui/material/Alert";
import DashLibraryItem from "./DashLibraryItem";
import DashLibraryFolder from "./DashLibraryFolder";
import DashLibraryRolesSpecial from "./DashLibraryRolesSpecial";
import DashLibraryRole from "./DashLibraryRole";
import DashLibraryUser from "./DashLibraryUser";
//import { selectDashPreview } from "./librarySlice";
import PreviewModal from "../preview/PreviewModal";
import { selectCurrentWorkspace, selectCurrentWorkspaceRole, selectWorkspaces } from "../auth/authSlice";
import { LibraryHeaderMenu } from "./header/LibraryHeaderMenu";
import { Dash } from "../../types/Dash";
import { Folder } from "../../types/Folder";

export default function Library() {
  const librarySlice = useAppSelector(selectLibrary);
  const filteredDashes = useAppSelector(
    selectFilteredDashes
  );
  const filteredFolders = useAppSelector(
    selectFilteredFolders
  );
  const rolesMode = useAppSelector(
    selectRolesMode
  );
  const viewRoleId = useAppSelector(
    selectRoleId
  );
  const folderStack = useAppSelector(
    selectFolderStack
  );
  const dispatch = useAppDispatch();
  //const documentPreview = useAppSelector(selectDocumentPreview);
  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const workspaces = useAppSelector(selectWorkspaces);
  const roles = useAppSelector(selectRoles);
  const usersInRole = useAppSelector(selectUsersInRole);
  const currentWorkspace = useAppSelector(selectCurrentWorkspace);

  function handlePreviewClose() {
    dispatch(clearPreview());
  }

  const roleFilteredDashes = filteredDashes.filter(_ => {
      return true;
  });

  const roleFilteredFolders = filteredFolders.filter(_ => {
    return true;
});

  const content = (
    <ImageList cols={6}>
      { !rolesMode && currentWorkspaceRole == "ADMIN" && folderStack.length == 0 && 
        <DashLibraryRolesSpecial
        key={"roles_special_1"}
        workspaceRole={currentWorkspaceRole}
        workspaces={workspaces}
        currentWorkspace={currentWorkspace}
      />
      }
      { !rolesMode && roleFilteredFolders.map((folder) => (
          <DashLibraryFolder
            key={folder.id}
            folder={folder as Folder}
            workspaceRole={currentWorkspaceRole}
            workspaces={workspaces}
            currentWorkspace={currentWorkspace}
            favourite={false}
            roles={roles}
          />)) }

      { !rolesMode && roleFilteredDashes.map((dash) => (
          <DashLibraryItem
            key={dash.id}
            dash={dash as Dash}
            workspaceRole={currentWorkspaceRole}
            workspaces={workspaces}
            currentWorkspace={currentWorkspace}
            favourite={false}
            roles={roles}
          />)) }
      { rolesMode && viewRoleId < 0 && roles.map((role) => (
        <DashLibraryRole
        key={"role_" + role.id}
        role={role}
        workspaceRole={currentWorkspaceRole}
        workspaces={workspaces}
        currentWorkspace={currentWorkspace}
      />
      ))}
      { rolesMode && viewRoleId > 0 && usersInRole.map((user) => (
        <DashLibraryUser
        key={"user_" + user.id}
        user={user}
        roleId={viewRoleId}
        workspaceRole={currentWorkspaceRole}
        workspaces={workspaces}
        currentWorkspace={currentWorkspace}
      />
      ))}
     
    </ImageList>
  );

  // TODO: https://stackoverflow.com/questions/69383051/dynamically-adjust-imagelist-columns-based-on-screen-size?noredirect=1&lq=1
  return (
    <Box sx={{ bgcolor: grey[200], px: 5, py: 1, height: "100vh" }}>
      {/* Slightly awkward way to pad the top as per MUI guidelines */}
      <Toolbar />
      <LibraryHeaderMenu />
      {librarySlice.error !== undefined ? (
        <Alert
          severity="error"
          onClose={() => {
            dispatch(setError(undefined));
          }}
        >
          {librarySlice.error}
        </Alert>
      ) : null}
      <Box sx={{ bgcolor: grey[200], 
        flexGrow: 2, 
        width: "100%",            display: "flex",
        flexDirection: "column",
        // height: "87vh",
        overflow: "hidden",
        // overflowY: "scroll",
}}>
        {/* <Box
          sx={{
            bgcolor: grey[400],
            px: 5,
            display: "flex",
            flexDirection: "column",
            height: "87vh",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        > */}
          {content}
        {/* </Box> */}
      </Box>
      { /*documentPreview != null ? (
        <PreviewModal
          setParentOpenHandler={handlePreviewClose}
          preview={documentPreview!}
        ></PreviewModal>
      ) : */ null}
    </Box>
  );
}

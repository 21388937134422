import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/hooks";

import {
  Chart,
} from "../../../../types/Dash";
import { selectSelected } from "../../dashEditorSlice";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { CollectionsBookmark } from "@mui/icons-material";
import { truncateHeading } from "../../../../services/utils";

export function TableOfContentsChart(props: {
  id: any;
  chart: Chart;
  icon: any;
  isDragging: boolean;
  draggableProps?: any;
}) {
  const actions = useActions();
  const selected = useAppSelector(selectSelected);

  // For managing subheadings.
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  let border = "1px solid lightgrey";
  if (props.isDragging) border = "2px dashed steelblue";
  if (props.chart === selected.chart) border = "1px solid orange";

  // TODO: Cleanup style
  return (
    <Box>
      <Box {...props.draggableProps}>
        <ListItemButton
          id={props.id}
          sx={{
            m: "0px",
            bgcolor: null,
            border: border,
            boxShadow: 1,
            borderRadius: 2,
            opacity: props.isDragging ? 0.5 : 1,
          }}
          onClick={handleClick}
        >
          <ListItemIcon>{props.icon}</ListItemIcon>
          <ListItemText primary={props.chart.name !== null && props.chart.name !== undefined ? truncateHeading(props.chart.name) + " (#" + props.chart.page_no + ")": "Not available"} />
          {/* Only show drop down if there are items */}
          { /*subItems !== null ? open ? <ExpandLess /> : <ExpandMore /> : null */}
        </ListItemButton>
      </Box>
      { /* subItems */}
    </Box>
  );
}

export default TableOfContentsChart;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  apiDeleteDash,
  apiGetDashes,
} from "../../services/DashAPI";
import {
  Chart,
  Dash,
  
} from "../../types/Dash";

import {
  Folder,
} from "../../types/Folder";

import {
  Role,
} from "../../types/Role";

import {
  User,
} from "../../types/User";

import DocumentTemplateInfoForm from "./DashInfoForm";

export interface LibraryState {
  dashes: Dash[];
  folders: Folder[];
  roles: Role[];

  // The current folder in a workspace
  currentFolderId: number;
  folderStack: Array<number>;
  folderNameStack: Array<string>;
  
  status: "idle" | "loading" | "failed";
  error: string | undefined;
  preview: string | undefined;
  filterString: string;
  viewDeleted: boolean;

  rolesMode: boolean;
  roleId: number;
  usersInRole: Array<User>;

  focusChart: null | Chart;
}

const initialState: LibraryState = {
  dashes: [] as Dash[],
  folders: [] as Folder[],
  roles: [] as Role[],

  // We start at the root folder
  currentFolderId: -1,
  folderStack: [],
  folderNameStack: [],
  
  status: "idle",
  error: undefined,
  preview: undefined,
  filterString: "",
  viewDeleted: false,

  rolesMode: false,
  roleId: -1,
  usersInRole: [],

  focusChart: null,
};

export const deleteDash = createAsyncThunk<
  { dashes: Dash[] },
  {
    organizationId: number;
    workspaceId: number;
    currentFolderId: number;
    dash: Dash;
  }
>("library/deleteDocumentTemplate", async (params) => {
  const response = await apiDeleteDash(
    params.organizationId,
    params.workspaceId,
    params.dash
  );
  const fetchLatestResponse = await apiGetDashes(
    params.organizationId,
    params.workspaceId,
    params.currentFolderId
  );
  // The value we return becomes the `fulfilled` action payload
  return fetchLatestResponse.data;
});

export const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    setStatus: (
      state,
      action: PayloadAction<{
        status: "idle" | "loading" | "failed";
        error: string | undefined;
      }>
    ) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setDashes: (
      state,
      action: PayloadAction<Dash[]>
    ) => {
      state.dashes = action.payload;
    },
    setFolders: (
      state,
      action: PayloadAction<Folder[]>
    ) => {
      state.folders = action.payload;
    },
    setRoles: (
      state,
      action: PayloadAction<Role[]>
    ) => {
      state.roles = action.payload;
    },
    setPreview: (state, action: PayloadAction<string>) => {
      state.preview = action.payload;
    },
    clearPreview: (state) => {
      state.preview = undefined;
    },
    setFilterString: (state, action: PayloadAction<string>) => {
      state.filterString = action.payload;
    },
    setViewDeleted: (state, action: PayloadAction<boolean>) => {
      state.viewDeleted = action.payload;
    },

    updateDashLocal: (state, action: PayloadAction<Dash>) => {
      const index = state.dashes.findIndex(dt => dt.id == action.payload.id);
      if (index < 0) return;
      // let newDocumentTemplates = [...state.documentTemplates];
      state.dashes.splice(index, 1, action.payload);

    },

    // For changing the folder to a sub folder.  -1 is home
    setCurrentFolderId: (state, action: PayloadAction<{id: number, name: string}>) => {
      state.currentFolderId = action.payload.id;

      if (state.folderStack[state.folderStack.length - 1] != action.payload.id && action.payload.id != -1) {
        state.folderStack = [...state.folderStack, action.payload.id];
        state.folderNameStack = [...state.folderNameStack, action.payload.name];
      }
      else if (action.payload.id == -1) {
        state.folderStack = [];
        state.folderNameStack = [];
      }
    },
    setFolderStack: (state, action: PayloadAction<Array<number>>) => {
      state.folderStack = action.payload;
    },
    setFolderNameStack: (state, action: PayloadAction<Array<string>>) => {
      state.folderNameStack = action.payload;
    },

    setRolesMode: (state, action: PayloadAction<boolean>) => {
      state.rolesMode = action.payload;
    },
    setRoleId: (state, action: PayloadAction<number>) => {
      state.roleId = action.payload;
    },
    setUsersInRole: (state, action: PayloadAction<Array<User>>) => {
      state.usersInRole = action.payload;
    },
    setFocusChart: (state, action:PayloadAction<Chart> ) => {
      state.focusChart = action.payload;
    }
    /*
    setImagePreview: (
      state,
      action: PayloadAction<{
        dash: Dash;
        imagePreview: DashImagePreview;
      }>
    ) => {
      // Get the original document template.
      const index = state.dashes.findIndex(dt => dt.id === action.payload.dash.id);
      if(index !== -1)
        state.dashes[index].imagePreview = action.payload.imagePreview;
      else console.error(`Cannot find DocumentTemplate with ID ${action.payload.dash.id}`);
    },*/

  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteDash.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDash.fulfilled, (state, action) => {
        state.status = "idle";
        state.dashes = action.payload.dashes;
      })
      .addCase(deleteDash.rejected, (state, action) => {
        state.status = "idle";
        state.error = `${action.error?.code}: ${action.error?.message}`;
      });
  },
});

export const {
  setStatus,
  setError,
  setDashes,
  setFolders,
  setPreview,
  setRoles,
  clearPreview,
  setCurrentFolderId,
  setFolderNameStack,
  setFolderStack,
  //setImagePreview,
  setFilterString,

  setRoleId,
  setRolesMode,
  setUsersInRole,

  setFocusChart,
  
  setViewDeleted,
  updateDashLocal
} = librarySlice.actions;

//Selectors
export const selectLibrary = (state: RootState) => state.library;

export const selectCurrentFolder = (state: RootState) => state.library.currentFolderId;
export const selectFolderStack = (state: RootState) => state.library.folderStack;
export const selectFolderNameStack = (state: RootState) => state.library.folderNameStack;

export const selectRolesMode = (state: RootState) => state.library.rolesMode;
export const selectRoleId = (state: RootState) => state.library.roleId;

export const selectDashes = (state: RootState) => state.library.dashes;
export const selectRoles = (state: RootState) => state.library.roles;
export const selectUsersInRole = (state: RootState) => state.library.usersInRole;
export const selectFilteredDashes = (state: RootState) => {
  const filter = state.library.filterString;
  const dashes = state.library.dashes.filter(d => d.name.toLowerCase().includes(filter.toLowerCase()));

  // for (const dt of documentTemplates)
  //   dt._type = "template";
  // for (const dr of documentRecords)
  //   dr._type = "record";
  const list = [...dashes]

  // const list = [...documentTemplates.map(dt => Object.assign({}, dt, {_type: "template"})), ...documentRecords.map(dr => Object.assign({}, dr, {_type: "record"}))]
  // Sort the list.
  list.sort((a,b) => a.name.localeCompare(b.name));
  return list;
}
export const selectFilteredFolders = (state: RootState) => {
  const filter = state.library.filterString;
  const folders = state.library.folders.filter(d => d.name.toLowerCase().includes(filter.toLowerCase()));

  // for (const dt of documentTemplates)
  //   dt._type = "template";
  // for (const dr of documentRecords)
  //   dr._type = "record";
  const list = [...folders]

  // const list = [...documentTemplates.map(dt => Object.assign({}, dt, {_type: "template"})), ...documentRecords.map(dr => Object.assign({}, dr, {_type: "record"}))]
  // Sort the list.
  list.sort((a,b) => a.name.localeCompare(b.name));
  return list;
}

export const selectFocusChart = (state: RootState) => state.library.focusChart;

export default librarySlice.reducer;

import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions from "../../app/hooks";
import { useSnackbar } from "notistack";
import {
  Organization,
  User,
  Workspace,
  WorkspaceUserRole,
} from "../../types/User";
import {
  Role
} from "../../types/Role";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Workspaces } from "@mui/icons-material";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


/**
 * Edit the workspace if a workspace is passed in, otherwise create a new one.
 *
 * @export
 * @return {*}
 */
export default function WorkspaceConfigForm(props: {
  closeDialogHandler: any;
  newWorkspaceHandler: any;
  organization: Organization;
  workspace: Workspace | undefined;
  workspaces: Array<Workspace>;
  roles: Role [];
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [name, setName] = React.useState<string>(
    props.workspace?.name ?? "New Workspace"
  );
  const [nameError, setNameError] = React.useState("");
  const [dialogError, setDialogError] = React.useState("");

  const [addUser, setAddUser] = React.useState<User | undefined>(
    props.organization.users && props.organization.users.length > 0
      ? props.organization.users[0]
      : undefined
  );
  const [addUserRole, setAddUserRole] = React.useState<string>("VIEWER");

  const [newUserEmail, setNewUserEmail] = React.useState<string>("");
  const [newRoleName, setNewRoleName] = React.useState<string>("");
  const [newUserPassword, setNewUserPassword] = React.useState<string>("");

  const handleEditUserEmail = (e: any) => {
    setNewUserEmail(e.target.value);
  };

  const handleEditRoleName = (e: any) => {
    setNewRoleName(e.target.value);
  };

  const handleEditUserPassword = (e: any) => {
    setNewUserPassword(e.target.value);
  };

  const handleCreateRole = (e: any) => {
    actions.createRole({
      name: newRoleName
    });
  }

  const actions = useActions();

  const handleCancel = () => {
    props.closeDialogHandler();
  };

  const handleClose = () => {
    if(props.workspace === undefined) {
      //Create the workspace.
      if (props.workspaces.filter(_ => _.name == name).length > 0) {
        enqueueSnackbar("A workspace by that name already exists");
      }
      else {
        if (name == "FREE CONTENT") {
          enqueueSnackbar("You can't use that name for a workspace because it is a system name");
        }
        else {
          actions.createWorkspace({name: name, role_type: "ADMIN"});
          props.newWorkspaceHandler(name);
        }
      }
    }
    else {
      props.closeDialogHandler();
    }
  };

  const handleSetName = (name: string) => {
    if (name.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(name);
  };

  const handleSelectUser = (e: SelectChangeEvent) => {
    setAddUser(props.organization.users?.find((u) => u.id === e.target.value));
  };

  const handleSelectRole = (e: SelectChangeEvent) => {
    setAddUserRole(e.target.value);
  };

  const handleSelectRoleChange = (e: SelectChangeEvent, user_id:number, workspace:Workspace | undefined) => {
    //console.log(userRole);
    actions.changeUserRole({ role: e.target.value, user_id: user_id, workspace: workspace});
  };

  const handleAddUser = (e: any) => {
    if (addUser == null) {
      setDialogError("Please select a user to add.");
      return;
    }
    //TODO: Check for duplicates.
    actions.attachUser({
      workspace: props.workspace!,
      user: addUser,
      role: addUserRole,
    });
  };

  const handleCreateUser = (e: any) => {
    /*if (addUser == null) {
      setDialogError("Please select a user to add.");
      return;
    }*/
    //TODO: Check for duplicates.

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!newUserEmail.match(validRegex)) {
      enqueueSnackbar("Not a valid email address");
      return;
    }

    actions.createUser({
      workspace: props.workspace!,
      email: newUserEmail,
      password: newUserPassword,
    });
  };

  const handleDeleteRole = (role: WorkspaceUserRole) => {
    actions.detachUser({ workspace: props.workspace!, user: role.user });
  };

  // The assigned user identities.
  const assignedUsers = new Map<string, User>();
  props.workspace?.user_roles?.forEach((userRole) =>
    assignedUsers.set(userRole.user.id, userRole.user)
  );

  // All users
  const allUsersItems = props.organization.users?.map((user) => (
    <MenuItem key={user.id} value={user.id}>
      {user.email}
    </MenuItem>
  ));
  // Remaining users (not assigned roles)
  const unassignedUsersItems = props.organization.users
    ?.filter((u) => !assignedUsers.has(u.id))
    .map((user) => (
      <MenuItem key={user.id} value={user.id}>
        {user.email}
      </MenuItem>
    ));

  const userEditCard = (
    <Card sx={{minHeight: "70vh"}}>
      { /*<CardHeader title="User Roles"></CardHeader> */ }
      <CardContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="Workspace edit tabs">
            <Tab label="WORKSPACE DETAILS" {...a11yProps(0)} />
            <Tab label="USER ACCESS" {...a11yProps(1)} />
            <Tab label="INVITE USER" {...a11yProps(2)} />
            { /*<Tab label="ROLES" {...a11yProps(3)} /> */}
          </Tabs>
        </Box>
        {/* Existing role types */}
        <CustomTabPanel value={value} index={0}>
          <Typography component={'div'} variant="h5" sx={{marginBottom: "20px"}}>WORKSPACE DETAILS</Typography>
          {dialogError !== "" ? (
            <Alert severity="error">{dialogError}</Alert>
          ) : null}
          <TextField
            autoFocus
            error={nameError ? true : false}
            margin="dense"
            id="name"
            label="Name"
            fullWidth
            variant="outlined"
            value={name}
            disabled={props.workspace !== undefined}
            onChange={(e) => handleSetName(e.target.value)}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Typography component={'div'} variant="h5" sx={{marginBottom: "20px"}}>USER MANAGEMENT</Typography>
          {props.workspace?.user_roles?.map((userRole) => (
            <Grid container spacing={2} key={"__user_roles_" + userRole.user.id}>
              <Grid item xs={5}>
                <Select
                  label="User"
                  value={userRole.user.id}
                  fullWidth={true}
                  disabled={true}
                >
                  {allUsersItems}
                </Select>
              </Grid>
              <Grid item xs={5}>
                <Select
                  label="Role"
                  value={userRole.role_type}
                  onChange={(event) => handleSelectRoleChange(event, parseInt(userRole.user.id), props.workspace)}
                  fullWidth={true}
                >
                  <MenuItem value="VIEWER">VIEWER</MenuItem>
                  <MenuItem value="ADMIN">ADMIN</MenuItem>
                  { /* <MenuItem value="APPROVER">APPROVER</MenuItem> */}
                </Select>
              </Grid>
              
              
              <Grid item xs={2} textAlign="center">
                <IconButton onClick={(e) => handleDeleteRole(userRole)}>
                  <DeleteIcon fontSize="large"></DeleteIcon>
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Select
                label="User"
                value={addUser?.id}
                onChange={handleSelectUser}
                fullWidth={true}
              >
                {unassignedUsersItems}
              </Select>
            </Grid>
            <Grid item xs={5}>
              <Select
                label="Role"
                value={addUserRole}
                onChange={handleSelectRole}
                fullWidth={true}
              >
                <MenuItem key="VIEWER" value="VIEWER">VIEWER</MenuItem>
                <MenuItem key="ADMIN" value="ADMIN">ADMIN</MenuItem>
                { /* <MenuItem key="APPROVER" value="APPROVER">APPROVER</MenuItem> */}
              </Select>
            </Grid>
            
            <Grid item xs={2}>
              <Button startIcon={<AddIcon />} onClick={handleAddUser}>
                Add User
              </Button>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Typography component={'div'} variant="h5" sx={{marginBottom: "20px"}}>INVITE A FRESH USER</Typography>
          <Box>
            <InputLabel>Email</InputLabel>
              <Input
                value={newUserEmail}
                onChange={handleEditUserEmail}
                fullWidth={true}
              />
          </Box>
          <Button startIcon={<AddIcon />} onClick={(event) => handleCreateUser(event)}>
            Create User
          </Button>
      
        </CustomTabPanel>
        { /*
        <CustomTabPanel value={value} index={3}>
          <Typography component={'div'} variant="h5" sx={{marginBottom: "20px"}}>WORKSPACE ROLES</Typography>
          {props.roles?.map((role) => (
            <Grid container spacing={2} key={"__role_" + role.id}>
              <Grid item xs={5}>
                { role.name }
              </Grid>
              <Grid item xs={2} textAlign="center">
              
              </Grid>
            </Grid>
          ))}
          
          <Box sx={{marginTop: "20px"}}>
            <InputLabel>Role name</InputLabel>
              <Input
                value={newRoleName}
                onChange={handleEditRoleName}
                fullWidth={true}
              />
          </Box>
          <Button startIcon={<AddIcon />} onClick={(event) => handleCreateRole(event)}>
            Create Role
          </Button>

        </CustomTabPanel> */ }
      </CardContent>
    </Card>
  );

  const userAddCard = (
    <Card>
      <TextField
            autoFocus
            error={nameError ? true : false}
            margin="dense"
            id="name"
            label="Name"
            fullWidth
            variant="outlined"
            value={name}
            disabled={props.workspace !== undefined}
            onChange={(e) => handleSetName(e.target.value)}
          />
    </Card> );

  return (
    <Dialog
      open={true}
      onClose={(e) => {
        props.closeDialogHandler();
      }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        {props.workspace == undefined ? "ADD" : "EDIT"} WORKSPACE
      </DialogTitle>
      <DialogContent>
        
        {props.workspace !== undefined ? userEditCard : userAddCard}
      </DialogContent>
      <DialogActions>
        {props.workspace === undefined ? <Button onClick={handleCancel}></Button> : null}
        <Button onClick={handleClose}>{props.workspace == undefined ? "Create" : "Close"}</Button>
      </DialogActions>
    </Dialog>
  );
}

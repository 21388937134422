import React, { useState } from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions from "../../../app/hooks";

import { Chart, Dash, Parameter } from "../../../types/Dash";


export default function ParameterForm(props: {
  dash: Dash;
  open: boolean;
  parentCloseHandle: () => void;
}) {
  const actions = useActions();


  const [name, setName] = useState("");
  const [defaultValue, setDefaultValue] = useState("");

  const [nameError, setNameError] = React.useState("");
  const [defaultValueError, setDefaultValueError] = React.useState("");

  function createParameter() {
    const newParameter = {
      name: name,
      default_value: defaultValue
    };

    actions.createParameter(newParameter);
    props.parentCloseHandle();
  }

  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(e.target.value);
  }

  function handleDefaultValueChange(e: any) {
    if (e.target.value.trim() === "") {
      setDefaultValueError("Invalid name");
    } else setDefaultValueError("");
    setDefaultValue(e.target.value);
  }

  return (
    <Dialog open={props.open} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>New Parameter</DialogTitle>
      <DialogContent>
        { /* <DialogContentText>Edit the Chart</DialogContentText> */ }
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          error={nameError !== ""}
          fullWidth
          variant="standard"
          value={name}
          onChange={handleNameChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="default_value"
          label="Default Value"
          error={defaultValueError !== ""}
          fullWidth
          variant="standard"
          value={defaultValue}
          onChange={handleDefaultValueChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={createParameter}
          type="submit"
          variant="contained"
          color="primary"
          
          disabled={nameError !== "" || defaultValueError !== ""}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

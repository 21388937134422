import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions from "../../app/hooks";

export default function CreateFolderForm(props: {
  open: boolean;
  parentCloseHandle: () => void;
}) {
  const actions = useActions();

  const [name, setName] = useState("");
  const [nameError, setNameError] = React.useState("Name");

  function createFolder() {
    const newFolder = {
      name: name,
      parent_folder_id: -1
    };
    actions.createFolder(newFolder);
    props.parentCloseHandle();
  }

  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(e.target.value);
  }

  return (
    <Dialog open={props.open} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>New Folder</DialogTitle>
      <DialogContent>
        <DialogContentText>Create a new folder</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          error={nameError !== ""}
          fullWidth
          variant="standard"
          onChange={handleNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={createFolder}
          type="submit"
          variant="contained"
          color="primary"
          disabled={nameError !== ""}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

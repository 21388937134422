import React, { useEffect, useRef, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Organization, User, Workspace } from "../../types/User";
import { Role } from "../../types/Role";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WorkspaceConfigForm from "./WorkspaceConfigForm";
import OrganizationConfigForm from "./OrganizationConfigForm";
import Divider from "@mui/material/Divider";

import { selectCurrentWorkspaceRole, selectOrganizationOwner } from "../auth/authSlice";
import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";

/**
 * The organization and workspace menu.
 *
 * @export
 * @param {{user: User | null, organizations: Organization[] | null, workspaces: Workspace[] | null}} props
 * @return {*}
 */
export function OrganizationsMenu(props: {
  user: User | null;
  organizations: Organization[] | null;
  workspaces: Workspace[] | null;
  roles: Role[];
}) {
  const actions = useActions();

  const [anchorElOrg, setAnchorElOrg] = React.useState<null | HTMLElement>(
    null
  );



  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const organizationOwner = useAppSelector(selectOrganizationOwner);

  const handleOpenOrgMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOrg(event.currentTarget);
  };

  const handleCloseOrgMenu = () => {
    setAnchorElOrg(null);
  };

  const [anchorElWorkspace, setAnchorElWorkspace] =
    React.useState<null | HTMLElement>(null);

  const handleOpenWorkspaceMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElWorkspace(event.currentTarget);
  };

  const handleCloseWorkspaceMenu = () => {
    setAnchorElWorkspace(null);
  };

  const handleClickOrg = (org: Organization) => {
    setAnchorElOrg(null);
    if (props.user?.default_organization_id !== org.id)
      actions.setOrganization(org);
  };

  const handleClickWorkspace = (workspace: Workspace) => {
    setAnchorElWorkspace(null);
    actions.setWorkspace(workspace);
  };

  const [waitingWorkspace, setWaitingWorkspace] =
    React.useState<string>("");
  const [isCreatingNewWorkspace, setIsCreatingNewWorkspace] =
    React.useState<boolean>(false);
  const [openWorkspaceConfig, setOpenWorkspaceConfig] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (waitingWorkspace != "") {
      const found = props.workspaces!.filter(_ => _.name == waitingWorkspace);

      
      if (found.length > 0) {
        // Update the workspace
        // actions.setWorkspace(found[0]);
        
        //actions.getWorkspace(found[0].id);
        // Edit it.

        //console.log("AAA", found[0]);

        //actions.setWorkspace(found[0]);

        setIsCreatingNewWorkspace(false);
        setOpenWorkspaceConfig(true);



        setWaitingWorkspace("");
      }
    }
  });

  const [openOrganizationConfig, setOpenOrganizationConfig] =
    React.useState<boolean>(false);

  const selectedOrg = props.organizations?.find(
    (org) => org.id === props.user?.default_organization_id
  );
  const selectedWorkspace = props.workspaces?.find(
    (workspace) => workspace.id === props.user?.default_workspace_id
  );

  const organizationMenuItems = props.organizations?.map((org) => (
    <MenuItem
      key={org.id}
      onClick={(e) => handleClickOrg(org)}
      selected={org === selectedOrg ? true : false}
    >
      {org === selectedOrg ? (
        <ListItemIcon>
          <StarIcon></StarIcon>
        </ListItemIcon>
      ) : null}
      <ListItemText> {org.name}</ListItemText>
    </MenuItem>
  ));

  const workspaceMenuItems = props.workspaces?.map((workspace) => (
    <MenuItem
      key={workspace.id}
      onClick={(e) => handleClickWorkspace(workspace)}
      selected={workspace === selectedWorkspace ? true : false}
    >
      <ListItemIcon>
        {workspace === selectedWorkspace ? <StarIcon></StarIcon> : null}
      </ListItemIcon>
      <ListItemText>{workspace.name}</ListItemText>
      { organizationOwner && <IconButton
        edge="end"
        aria-label="edit"
        onClick={(e) => {
          // Update the workspace
          
          actions.getWorkspace(workspace.id);
          // Edit it.
          setIsCreatingNewWorkspace(false);
          setOpenWorkspaceConfig(true);
        }}
      > 
        <MoreHorizIcon />
      </IconButton> }
    </MenuItem>
  ));

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Organizations">
        <Button
          color="info"
          variant="contained"
          onClick={handleOpenOrgMenu}
          endIcon={
            anchorElOrg == null ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )
          }
        >
          {selectedOrg?.name}
        </Button>
      </Tooltip>
      {" / "}
      <Tooltip title="Workspaces">
        <Button
          color="info"
          variant="contained"
          onClick={handleOpenWorkspaceMenu}
          endIcon={
            anchorElWorkspace == null ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )
          }
        >
          {selectedWorkspace
            ? selectedWorkspace.name
            : "[No Workspace Selected]"}
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElOrg}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElOrg)}
        onClose={handleCloseOrgMenu}
      >
        { organizationOwner && <MenuItem
          onClick={(e) => {
            setOpenOrganizationConfig(true);
          }}
        >
          <ListItemText>User Administration</ListItemText>
        </MenuItem>}
        <Divider></Divider>
        {organizationMenuItems}
        
      </Menu>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElWorkspace}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElWorkspace)}
        onClose={handleCloseWorkspaceMenu}
      >
        { organizationOwner &&
        <MenuItem
          onClick={(e) => {
            setIsCreatingNewWorkspace(true);
            setOpenWorkspaceConfig(true);
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText>Create New...</ListItemText>
        </MenuItem>}
        <Divider></Divider>
        {workspaceMenuItems}
      </Menu>
      {openWorkspaceConfig ? (
        <WorkspaceConfigForm
          workspace={isCreatingNewWorkspace ? undefined : selectedWorkspace}
          workspaces={props.workspaces!}
          organization={selectedOrg!}
          roles={props.roles}
          closeDialogHandler={() => {
            setIsCreatingNewWorkspace(false);
            setOpenWorkspaceConfig(false);
          }}
          newWorkspaceHandler={(name:string) => {
            setIsCreatingNewWorkspace(false);
            setOpenWorkspaceConfig(false);

            setWaitingWorkspace(name);
          }}
        ></WorkspaceConfigForm>
      ) : null}
      {openOrganizationConfig ? (
        <OrganizationConfigForm
          workspaces={props.workspaces!}
          organization={selectedOrg!}
          closeDialogHandler={() => {
            setOpenOrganizationConfig(false);
          }}
        ></OrganizationConfigForm>
      ) : null}
    </Box>
  );
}

import { delay, select } from "redux-saga/effects";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import {
  AuthState,
  selectAuth,
  selectUser,
  selectWorkspaces,
  setError as setAuthError,
  setStatus as setAuthStatus,
  setUser,
  setWorkspaceLocal,
  setWorkspaces,
} from "../../features/auth/authSlice";

import {
  setCurrentFolderId,
}  from "../../features/library/librarySlice";

import { User, Workspace } from "../../types/User";
import {
  apiAttachUser,
  apiChangeUserRole,
  apiCreateUser,
  apiCreateWorkspace,
  apiDeleteWorkspace,
  apiDetachUser,
  apiGetWorkspace,
  apiGetWorkspaces,
  apiUpdateUser,
} from "../../services/workspaceAPI";

import { 
  clearDash, 
} from "../../features/editor/dashEditorSlice";

/**
 * Create a workspace.
 *
 * @param {ReturnType<typeof actions.createWorkspace>} action
 * @return {*}
 */
export function* createWorkspace(
  action: ReturnType<typeof actions.createWorkspace>
) {

  yield put(setAuthStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;

    const response = yield call(
      apiCreateWorkspace,
      userState.default_organization_id,
      action.payload
    );

    //if ((response as any).workspace_id === undefined) {
    //}

    const workspace_id = response.data.workspace_id;

    const authState = (yield select(selectAuth)) as AuthState;
    const updatedUser = Object.assign({}, authState.user, {
      default_workspace_id: workspace_id,
    });

    // Clear any currently edited document
    yield put(clearDash());

    // Update the user
    yield put(setUser(updatedUser));
    
    yield put(actions.getWorkspaces());

    /*
    yield put(actions.getWorkspaces());

    const workspaces =(yield select(selectWorkspaces)) as Workspace[];

    const matches = workspaces.filter(_ => _.name == action.payload.name);

    console.log("All names", workspaces);

    if (matches.length > 0) {
      yield put(actions.setWorkspace(matches[0]));
    }*/

    // // Set the organizations.
    // // const workspaces = response.data.workspaces;
    // yield put(setWorkspaces(workspaces));

    // const workspaceId = userState.default_workspace_id;
    // let defaultWorkspace = workspaces.find(
    //   (w: Workspace) => w.id === workspaceId
    // );
    // if (!defaultWorkspace && workspaces.length > 0) {
    //   console.warn(
    //     `Cannot find user's default workspace ${workspaceId}, resetting to first workspace.`
    //   );
    //   // Select the first organization.
    //   defaultWorkspace = workspaces[0];
    //   const updatedUser = Object.assign({}, userState, {
    //     default_workspace_id: defaultWorkspace.id,
    //   });

    //   // Update the user
    //   yield put(setUser(updatedUser));

    //   // Get the document templates for this workspace
    //   yield put(actions.getDocumentTemplates());
    // }
  } catch (e) {
    console.error(String(e));
    yield put(setAuthError(String(e)));
    return;
  }
}

/**
 * Get all the information about a workspace.
 *
 * @export
 * @param {ReturnType<typeof actions.getWorkspace>} action
 */
export function* getWorkspace(action: ReturnType<typeof actions.getWorkspace>) {
  try {

    const userState = (yield select(selectUser)) as User;
    // Get the complete workspace information
    const resp = yield call(
      apiGetWorkspace,
      userState.default_organization_id!,
      action.payload
    );

    const workspace = resp.data.workspace as Workspace;
    workspace.user_roles = [];
    resp.data.users.forEach((u: User, i: number) => {
      workspace.user_roles!.push({
        user: u,
        role_type: resp.data.role_types[i],
      });
    });

    yield put(actions.getWorkspaceSummary());

    yield put(setWorkspaceLocal(workspace));

    //console.log("8888", workspace);
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

/**
 * Get all workspaces and make sure the user has a valid default workspace.
 *
 * @param {ReturnType<typeof actions.getWorkspaces>} action
 * @return {*}
 */
export function* getWorkspaces(
  action: ReturnType<typeof actions.getWorkspaces>
) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const resp = yield call(
      apiGetWorkspaces,
      userState.default_organization_id
    );
    const workspaces = resp.data.workspaces as Workspace[],
      roleTypes = resp.data.role_types;
    workspaces.forEach((workspace: Workspace, index: number) => {
      workspace.my_role = roleTypes[index];
    });

    // Set the workspaces.
    yield put(setWorkspaces(workspaces));

    //console.log("AAA", workspaces);
    

    const workspaceId = userState.default_workspace_id;

    //console.log("BBB", workspaceId);

    let defaultWorkspace = workspaces.find(
      (w: Workspace) => w.id === workspaceId
    );
    if (!defaultWorkspace && workspaces.length > 0) {
      console.warn(
        `Cannot find user's default workspace ${workspaceId}, resetting to first workspace.`
      );
      // Select the first workspace.
      defaultWorkspace = workspaces[0];
      // const updatedUser = Object.assign({}, userState, {
      //   default_workspace_id: defaultWorkspace.id,
      // });
    }
    // Set the default workspace to make sure we get it.
    yield put(actions.setWorkspace(defaultWorkspace!));
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

/**
 * Delete the workspace and set the default to the first one.
 *
 * @param {ReturnType<typeof actions.deleteWorkspace>} action
 * @return {*}
 */
export function* deleteWorkspace(
  action: ReturnType<typeof actions.deleteWorkspace>
) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  try {
    const authState = (yield select(selectAuth)) as AuthState;

    const resp = yield call(apiDeleteWorkspace,
      authState.user!.default_organization_id!,
      action.payload);

    const workspaceId = authState.user?.default_workspace_id;
    let defaultWorkspace = authState.workspaces?.find(
      (w: Workspace) => w.id === workspaceId
    );
    if (!defaultWorkspace) {
      if (authState?.workspaces && authState.workspaces.length > 0) {
        console.warn(
          `Cannot find user's default workspace ${workspaceId}, resetting to first workspace.`
        );
        // Select the first workspace.
        defaultWorkspace = authState.workspaces[0];
        // const updatedUser = Object.assign({}, userState, {
        //   default_workspace_id: defaultWorkspace.id,
        // });
      }
    }
    // Set the default workspace to make sure we get it.
    yield put(actions.setWorkspace(defaultWorkspace!));
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

/**
 * Set the current workspace and refresh all downstream slices.
 *
 * @param {ReturnType<typeof actions.setWorkspace>} action
 * @return {*}
 */
export function* setCurrentWorkspace(
  action: ReturnType<typeof actions.setWorkspace>
) {
  try {
    const reqWorkspace = action.payload;

    const authState = (yield select(selectAuth)) as AuthState;
    const updatedUser = Object.assign({}, authState.user, {
      default_workspace_id: reqWorkspace.id,
    });

    // 28APR
    // Update defaults and go to root
    const orgId = authState.user!.default_organization_id!;
    const userId = parseInt(authState.user!.user_id!);

    console.log(authState.user!.user_id!);

    if (userId > 0) {    
      const resp = yield call(apiUpdateUser,
        orgId as number,
        reqWorkspace.id as number,
        userId);

      //console.log(resp);
    }

    yield put(setCurrentFolderId({id:-1, name:""}));

    // Clear any currently edited document
    yield put(clearDash());

    // Update the user
    yield put(setUser(updatedUser));

    // Get all the information about the current workspace.
    yield put(actions.getWorkspace(reqWorkspace.id));

    // Get the document templates for this workspace
    yield put(actions.getDashes());
    yield put(actions.getFolders());
    yield put(actions.getRoles());
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

/**
 * Attach a user to a workspace.
 *
 * @param {ReturnType<typeof actions.setWorkspace>} action
 * @return {*}
 */
export function* attachUser(action: ReturnType<typeof actions.attachUser>) {
  try {
    const workspace = action.payload.workspace,
      user = action.payload.user,
      role = action.payload.role;

    const authState = (yield select(selectAuth)) as AuthState;

    const response = yield apiAttachUser(
      authState.user!.default_organization_id,
      workspace,
      user,
      role
    );

    // Get all the information about the current workspace.
    yield put(actions.getWorkspace(workspace.id));
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

/**
 * Create a user and attach to a workspace.
 *
 * @param {ReturnType<typeof actions.setWorkspace>} action
 * @return {*}
 */
 export function* createUser(action: ReturnType<typeof actions.createUser>) {
  try {
    console.log("Here");

    const workspace = action.payload.workspace,
      email = action.payload.email,
      password = action.payload.password;


    const authState = (yield select(selectAuth)) as AuthState;

    const response = yield apiCreateUser(
      authState.user!.default_organization_id,
      workspace,
      email,
      password
    );

    // Get new user list
    yield put(actions.getOrganizationUsers());

    // Get all the information about the current workspace.
    yield put(actions.getWorkspace(workspace.id));
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

/**
 * Detach a user from a workspace.
 *
 * @param {ReturnType<typeof actions.setWorkspace>} action
 * @return {*}
 */
export function* detachUser(action: ReturnType<typeof actions.detachUser>) {
  try {
    const authState = (yield select(selectAuth)) as AuthState;
    const response = yield apiDetachUser(
      authState.user!.default_organization_id,
      action.payload.workspace,
      action.payload.user);

    // Get all the information about the current workspace.
    yield put(actions.getWorkspace(action.payload.workspace.id));
    
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

/**
 * Change a user's role
 *
 * @param {ReturnType<typeof actions.setWorkspace>} action
 * @return {*}
 */
 export function* changeUserRole(action: ReturnType<typeof actions.changeUserRole>) {
  try {
    if (action.payload.workspace !== undefined) {
    
      const authState = (yield select(selectAuth)) as AuthState;
      const response = yield apiChangeUserRole(
        authState.user!.default_organization_id,
        action.payload.workspace,
        action.payload.user_id,
        action.payload.role
        );

      // Get all the information about the current workspace.
      yield put(actions.getWorkspace(action.payload.workspace.id));
    }
    
  } catch (e) {
    yield put(setAuthError(String(e)));
    return;
  }
}

import { delay, fork, select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import { selectUser } from "../../features/auth/authSlice";
import { User } from "../../types/User";
import { Role } from "../../types/Role";
import {
  setError as setDashError,
  setStatus as setDashStatus,
  setRoles,
  setUsersInRole,
  //setImagePreview,
  updateDashLocal,
} from "../../features/library/librarySlice";
import * as actions from "../actions";

import {
  apiCreateRole,
  apiGetRoles,
  apiGetUsersInRole,
  apiLinkDashToRole,
  apiLinkFolderToRole,
  apiLinkRole,
  apiUnlinkRole,
} from "../../services/RoleAPI";

export function* createRole(
  action: ReturnType<typeof actions.createRole>
  ) {
  yield put(setDashStatus({ status: "loading", error: undefined }));
  
  try {
    const userState = (yield select(selectUser)) as User;
    const createResponse = yield call(
      apiCreateRole,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );
  
    yield put(actions.getRoles());

  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* getRoles(
  action: ReturnType<typeof actions.getRoles>
  ) {
  yield put(setDashStatus({ status: "loading", error: undefined }));
  
  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiGetRoles,
      userState.default_organization_id,
      userState.default_workspace_id
    );

    yield put(setRoles(response.data.roles));
  
    yield put(setDashStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* getUsersInRole(
  action: ReturnType<typeof actions.getUsersInRole>
  ) {
  yield put(setDashStatus({ status: "loading", error: undefined }));
  
  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiGetUsersInRole,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    yield put(setUsersInRole(response.data.users_in_role));
  
    yield put(setDashStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* linkRole(
  action: ReturnType<typeof actions.linkRole>
  ) {
  yield put(setDashStatus({ status: "loading", error: undefined }));
  
  try {
    const userState = (yield select(selectUser)) as User;
    const createResponse = yield call(
      apiLinkRole,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.roleId,
      action.payload.userId
    );
  
    yield put(actions.getUsersInRole(action.payload.roleId));

  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* unlinkRole(
  action: ReturnType<typeof actions.linkRole>
  ) {
  yield put(setDashStatus({ status: "loading", error: undefined }));
  
  try {
    const userState = (yield select(selectUser)) as User;
    const createResponse = yield call(
      apiUnlinkRole,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.roleId,
      action.payload.userId
    );
  
    yield put(actions.getUsersInRole(action.payload.roleId));

  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* linkFolderToRole(
  action: ReturnType<typeof actions.linkFolderToRole>
  ) {
  yield put(setDashStatus({ status: "loading", error: undefined }));
  
  try {
    const userState = (yield select(selectUser)) as User;
    const createResponse = yield call(
      apiLinkFolderToRole,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.folderId,
      action.payload.roleId
    );
  
    //yield put(actions.getUsersInRole(action.payload.roleId));

  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* linkDashToRole(
  action: ReturnType<typeof actions.linkDashToRole>
  ) {
  yield put(setDashStatus({ status: "loading", error: undefined }));
  
  try {
    const userState = (yield select(selectUser)) as User;
    const createResponse = yield call(
      apiLinkDashToRole,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.dashId,
      action.payload.roleId
    );
  
    //yield put(actions.getUsersInRole(action.payload.roleId));

  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

import React, { useState } from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions from "../../app/hooks";
import {
  User,
  Workspace,
  WorkspaceUserRole,
} from "../../types/User";

export default function AttachUserForm(props: {
  open: boolean;
  workspaces: Workspace[];
  workspaceId: number;
  roleId: number;
  parentCloseHandle: () => void;
}) {
  const actions = useActions();

  const [userId, setUserId] = useState(-1);
  const [userIdError, setUserIdError] = React.useState("");

  const selectedWorkspace = props.workspaces.find(
    (workspace) => workspace.id === props.workspaceId
  );

  function attachUserToRole() {
    if (selectedWorkspace !== undefined) {
      actions.linkRole({
        roleId: props.roleId,
        userId: userId,
      });
    }
    props.parentCloseHandle();
  }

  function handleUserIdChange(e: any) {
    setUserId(parseInt(e.target.value));
  }

  return (
    <Dialog open={props.open} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>New Role</DialogTitle>
      <DialogContent>
        <DialogContentText>Select user to attach to role</DialogContentText>
        <Select
          label="User"
          value={userId}
          onChange={(event) => handleUserIdChange(event)}
          fullWidth={true}
        >
          <MenuItem value="-1">Please select...</MenuItem>
          { selectedWorkspace !== undefined && selectedWorkspace.user_roles !== null && selectedWorkspace.user_roles !== undefined && selectedWorkspace.user_roles.map( _ => 
            <MenuItem value={_.user.id}>{ _.user.email }</MenuItem> )
          }  
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={attachUserToRole}
          type="submit"
          variant="contained"
          color="primary"
          disabled={userIdError !== ""}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { client } from "./client";
import {
  Chart,
  ChartCreateRequest,
  Dash,
  DashCreateRequest, 
  DataTable,
  DataTableCreateColumnRequest,
  DataTableCreateRequest,
  Navigation,
  NavigationCreateRequest,
  Parameter,
  ParameterCreateRequest,
  Workspace,
} from "../types/Dash";

import {
  Folder, FolderCreateRequest,
} from "../types/Folder";

export function apiCreateFolder(
  organizationId: number,
  workspaceId: number,
  folderCreateRequest: FolderCreateRequest
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/folders`,
    folderCreateRequest
  );
}

export function apiGetFolders(
  organizationId: number,
  workspaceId: number,
  currentFolderId: number,
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/folders_by_folder/${currentFolderId}`
  );
}

export function apiDeleteFolder(
  organizationId: number,
  workspaceId: number,
  folderToDelete: Folder
) {
  return client.delete(
    `/organizations/${organizationId}/folders/${folderToDelete.id}`
  );
}

import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import { Chart, Dash } from "../../../types/Dash";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";

import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectCurrentWorkspaceRole
} from "../../auth/authSlice";

export function ChartAdder(props: {
  organizationId: number;
  workspaceId: number;
  dash: Dash;
  pageNo: number,
  isFloatingInsert?: boolean | undefined;
  chartBelowInsert?: Chart
}) {
  const actions = useActions();

  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);

  function addChartToEnd() {
    // Find the maximum component order
    const component_order =
      props.dash.charts!.length > 0
        ? Math.max(
            ...props.dash.charts!.map(function (o) {
              return o.chart_order;
            })
          ) + 10
        : 0;

    actions.createChart({
      name: "Untitled",
      chart_order: component_order,
      chart_type: "MULTILINE",
      data_source: "{}",
      code: "",
      code_result: "",
      code_error: "",
      code_lang: "RUST",
      page_no: props.pageNo
    });
  }

  function insertChart() {
    const newChart = {
      name: "Untitled",
      chart_order: props.chartBelowInsert!.chart_order,
      chart_type: "MULTILINE",
      data_source: "{}",
      code: "",
      code_result: "",
      code_error: "",
      code_lang: "RUST",
      page_no: props.pageNo
    };
    actions.createChartAboveChart({
      belowChart: props.chartBelowInsert!,
      insertedChart: newChart
    })
  }

  const onClick = (e: any) => {
    if(!props.isFloatingInsert) 
      addChartToEnd();
    else
      insertChart();
  };

  // Float style?
  const style = props.isFloatingInsert
    ? { mx: 15, height: 0, flexGrow: 1, zIndex: 1, position: 'relative'}
    : { mx: 15, height: "100vh" }

  return (
    <Box sx={style}>
      { (currentWorkspaceRole == "ADMIN") && <Grow in={true}>
        <Divider>
          <Button
            onClick={onClick}
            onMouseDown={(e) => e.preventDefault()}
            startIcon={<AddCircleIcon fontSize="large" />}
            size="large"
          >
            {props.isFloatingInsert ? "Insert" : "Add"} Chart
          </Button>
        </Divider>
      </Grow> }
    </Box>
  );
}

import { client } from './client'; 
import { Organization } from '../types/User';

export function apiGetOrganizations() {
  return client.get('/organizations')
}

export function apiGetOrganization(organizationId: number) {
  return client.get(`/organizations/${organizationId}`)
}

export function apiGetOrganizationUsers(organizationId: number) {
  return client.get(`/organizations/${organizationId}/users`)
}

export function apiCreateOrganization(organization: Organization) {
  return client.post(`/organizations`, organization)
}

export function apiUpdateOrganization(organization: Organization) {
  return client.put(`/organizations/${organization.id}`, organization)
}

export function apiCreateUserInOrg(organizationId: number, email: string, password: string, workspaces: {id: number, role: string}[]) {
  return client.post(`/organizations/${organizationId}/users`, {
    email, password, workspaces
  })
}

export function apiCreateUserWithOrg(email: string, organization_name: string) {
  return client.post(`/admin_users`, {
    email, organization_name
  })
}

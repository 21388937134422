import { client } from "./client";
import {
  Role, RoleCreateRequest,
} from "../types/Role";

export function apiCreateRole(
  organizationId: number,
  workspaceId: number,
  roleCreateRequest: RoleCreateRequest
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/roles`,
    roleCreateRequest
  );
}

export function apiGetRoles(
  organizationId: number,
  workspaceId: number
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/roles`
  );
}

export function apiGetUsersInRole(
  organizationId: number,
  workspaceId: number,
  roleId: number,
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/roles/${roleId}/users`
  );
}

export function apiLinkRole(
  organizationId: number,
  workspaceId: number,
  roleId: number,
  userId: number
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/roles/${roleId}/link_user`,
    {
      user_id: userId,
    }
  );
}

export function apiUnlinkRole(
  organizationId: number,
  workspaceId: number,
  roleId: number,
  userId: number
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/roles/${roleId}/unlink_user`,
    {
      user_id: userId,
    }
  );
}

export function apiLinkDashToRole(
  organizationId: number,
  workspaceId: number,
  dashId: number,
  roleId: number
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/dashes/${dashId}/link_role`,
    {
      role_id: roleId,
    }
  );
}

export function apiLinkFolderToRole(
  organizationId: number,
  workspaceId: number,
  folderId: number,
  roleId: number
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/folders/${folderId}/link_role`,
    {
      role_id: roleId,
    }
  );
}
import { combineReducers } from 'redux';
import documentReducer from '../features/editor/dashEditorSlice';
import libraryReducer from '../features/library/librarySlice';
import authReducer from '../features/auth/authSlice';
import preferencesReducer from '../features/editor/header/preferencesSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    library: libraryReducer,
    document: documentReducer,
    preferences: preferencesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
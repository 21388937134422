import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import HomeIcon from "@mui/icons-material/Home";
import BackIcon from "@mui/icons-material/ArrowBack";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SearchIcon from "@mui/icons-material/Search";
import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";

import { grey } from "@mui/material/colors";
import { Checkbox, Divider, InputAdornment, TextField } from "@mui/material";
import {
  selectFolderNameStack,
  selectLibrary,
  selectRoleId,
  selectRolesMode,
  setFilterString,
  setRoleId,
  setRolesMode,
  setViewDeleted,
} from "../librarySlice";

export function LibraryHeaderMenu() {
  const actions = useActions();
  const librarySlice = useAppSelector(selectLibrary);
  const folders = useAppSelector(selectFolderNameStack);
  const dispatch = useAppDispatch();

  const rolesMode = useAppSelector(
    selectRolesMode
  );

  const viewRoleId = useAppSelector(
    selectRoleId
  );

  return (
    <AppBar position="relative" sx={{ borderRadius: 2 }}>
      <Toolbar variant="dense" sx={{ bgcolor: grey[200], borderRadius: 2 }}>
        <Box>
          <TextField
            sx={{ background: "#FFFFFF" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Filter"
            variant="standard"
            value={librarySlice.filterString}
            onChange={(e) => dispatch(setFilterString(e.target.value))}
          ></TextField>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }}></Divider>
        <Box>
          { /* <Tooltip title="Show archived">
            <Checkbox
              icon={<DeleteIcon />}
              checkedIcon={<DeleteIcon />}
              checked={librarySlice.viewDeleted}
              onClick={(e) =>
                dispatch(setViewDeleted(!librarySlice.viewDeleted))
              }
              onMouseDown={(e) => e.preventDefault()}
            />
            </Tooltip> */}
            { !rolesMode && <Tooltip title="Go to the home folder">
              <Button
                onClick={(e) =>
                  actions.changeFolder({id:-1, name:""})
                }
                onMouseDown={(e) => e.preventDefault()}
              >
                <HomeIcon></HomeIcon>
              </Button>
            </Tooltip> }
            { !rolesMode && <Tooltip title="Go back one folder">
              <Button
                onClick={(e) =>
                  actions.backFolder()
                }
                onMouseDown={(e) => e.preventDefault()}
              >
                <BackIcon></BackIcon>
              </Button>
            </Tooltip> }
            { !rolesMode &&  <Tooltip title="Your current folder">
              <Button>{ folders.join(" / ") }</Button>
            </Tooltip> }
            { rolesMode && viewRoleId < 0 && <Tooltip title="Leave roles" onClick={(e) => {
                  dispatch(setRolesMode(false));
                  dispatch(setRoleId(-1));
            }
                }>
              <Button><BackIcon></BackIcon>ROLES</Button>
            </Tooltip> }

            { rolesMode && viewRoleId > 0 && <Tooltip title="Leave role" onClick={(e) => {
                  dispatch(setRoleId(-1));
            }
                }>
              <Button><BackIcon></BackIcon>ROLE</Button>
            </Tooltip> }
        </Box>
      </Toolbar>
    </AppBar>
  );
}

import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions from "../../app/hooks";

export default function CreateDashForm(props: {
  open: boolean;
  parentCloseHandle: () => void;
}) {
  const actions = useActions();

  const [name, setName] = useState("");
  const [terms, setTerms] = useState("");
  const [nameError, setNameError] = React.useState("Name");
  const [termsError, setTermsError] = React.useState("Terms");

  function createDash() {
    const newDash = {
      name: name,
      terms: terms,
      parent_folder_id: -1,
    };
    actions.createDash(newDash);
    props.parentCloseHandle();
  }

  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(e.target.value);
  }

  function handleTermsChange(e: any) {
    if (e.target.value.trim() === "") {
      setTermsError("Invalid purpose");
    } else setTermsError("");
    setTerms(e.target.value);
  }

  return (
    <Dialog open={props.open} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>New Workbook</DialogTitle>
      <DialogContent>
        <DialogContentText>Create a new workbook</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          error={nameError !== ""}
          fullWidth
          variant="standard"
          onChange={handleNameChange}
        />
        <TextField
          margin="dense"
          id="terms"
          label="Terms"
          fullWidth
          error={termsError !== ""}
          variant="standard"
          onChange={handleTermsChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={createDash}
          type="submit"
          variant="contained"
          color="primary"
          disabled={nameError !== ""}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useState } from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Dash, Workspace } from "../../types/Dash";
import { Folder } from "../../types/Folder";
import { Role } from "../../types/Role";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Box";
import useActions from "../../app/hooks";

export default function AttachRoleForm(props: {
  parentCloseHandle: () => void;
  dash: Dash | null;
  folder: Folder | null;
  roles: Array<Role> | null;
  currentWorkspace: Workspace | null;
}) {
  const actions = useActions();

  const [selectedRoleId, setSelectedRoleId] = useState(-1);

  function handleAttachRole() {
    if (props.folder !== null) {
      actions.linkFolderToRole({
        roleId: selectedRoleId,
        folderId: props.folder.id,
      });
    } 
    
    if (props.dash !== null) {
      actions.linkDashToRole({
        roleId: selectedRoleId,
        dashId: props.dash.id,
      });
    }

    props.parentCloseHandle();
    /*const newDocumentTemplate = Object.assign({}, props.documentTemplate, {
      name: name,
      purpose: purpose,
      category: category,
    });
    actions.updateDocumentTemplate(newDocumentTemplate);
    props.parentCloseHandle();

    const selWorkspaces = props.workspaces!.filter(_ => _.id == workspaceId);

    if (selWorkspaces.length > 0) {
      actions.moveDash({dash: props.dash, target_workspace_id: selWorkspaces[0].id});
      props.parentCloseHandle();
    }*/

    console.log("Beer");
  }

  function handleSelectRole(e: any) {
    setSelectedRoleId(e.target.value);
  }

  return (
    <Dialog open={true} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>
        { props.dash !== null && <Typography sx={{marginBottom: "20px"}}>Attach Role to: {props.dash.name}</Typography> }
        { props.folder !== null && <Typography sx={{marginBottom: "20px"}}>Attach Role to: {props.folder.name}</Typography> }

        { /* <Typography>Select your target workspace</Typography> */ }
      </DialogTitle>
      
      <DialogContent>
        <Box component="form" sx={{ p: 2, m: 1 }}>
        <Select
          value={selectedRoleId}
          onChange={handleSelectRole}
          fullWidth={true}
        >
          <MenuItem value={-1}>Please select...</MenuItem>
          { props.roles!.map( _ => 
            <MenuItem value={_.id}>{_.name}</MenuItem>
          )}
        </Select>

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={handleAttachRole}
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            selectedRoleId == -1
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
